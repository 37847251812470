class Cookies {
  constructor(cookieBanner, cookieBannerAccept) {
    cookieBannerAccept.addEventListener('click', () => {
      this.setCookieAgreed(1);
      cookieBanner.style.display = 'none';
    });

    const existingCookie = this.getCookie('cookie_agreed');
    if (existingCookie === '' || existingCookie === null) {
      cookieBanner.style.display = 'flex';
    }
  }

  setCookieAgreed(agreed) {
    document.cookie = `cookie_agreed=${agreed}`;
  }

  getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new Cookies(
    document.querySelector('#cookie-banner'),
    document.querySelector('#cookie-banner-accept'),
  );
});
