import logger from 'components/Logger';

function trackModal(modalId) {
  track({ event: 'modal', modal: modalId });
}

function trackFeedback(helpfulText) {
  track({ event: 'feedback', feedback: helpfulText });
}

function trackSocialSharing(type, page) {
  track({ event: 'shared', shared: { on: type, page } });
}

function track(params) {
  if (typeof dataLayer === 'undefined') {
    window.dataLayer = [];
  }

  logger.group('GTM track');
  logger.log(params);
  logger.groupEnd();

  dataLayer.push(params);
}

function trackPage() {
  // Retrieve the gtm_track query parameter and pass it to the datalayer
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if (params.gtm_track !== null) {
    track(JSON.parse(params.gtm_track));
  }
}

export default {
  track,
  trackModal,
  trackFeedback,
  trackSocialSharing,
  trackPage,
};
