/*----------------------------------------*\
  COMMON Bundle
\*----------------------------------------*/

/**
 * Polyfills
 */
import 'polyfills';
/**
 * Styleguide
 */
import '@lex4you/styleguide/dist/assets/styleguide/icons/icons.svg';
import '@lex4you/styleguide/dist/assets/styleguide/images/logo-business.svg';
import '@lex4you/styleguide/dist/assets/styleguide/images/logo-tcs.png';
import '@lex4you/styleguide/dist/assets/styleguide/images/logo.svg';
import '@lex4you/styleguide/dist/assets/styleguide/scripts/styleguide.js';
import '@lex4you/styleguide/dist/assets/styleguide/styles/styleguide.css';
import '@lex4you/styleguide/dist/favicon.ico';
/**
 * Misc.
 */
import 'components/monitoring';
import 'components/Sharing';
import 'extensions/HtmlExtensions';
import 'stylesheets/site.scss';
import 'packs/cookies';

/**
 * Debug
 */
import debug from 'components/Debug';

import gtm from '../components/Gtm';
gtm.trackPage();

window.DEBUG = debug;
